import { Injectable } from '@angular/core';
import { PermissionEnum } from '../../../features/web/admin-management/constants/module-permission.constant';
import { ModuleEnum } from '../../../features/web/admin-management/constants/module.constant';
import { LoginApiModel } from '../../models/login-model';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private currentLoginUser: LoginApiModel | null = null;
    private readonly ADMIN_KEY = 'fnn_admin';
    private readonly MODULE_PERMISSIONS_KEY = 'module_permissions';
    private modulePermissions: Record<string, number[]> = {};

    constructor() {
        this.loadPermissionsFromStorage();
    }

    get deviceId(): string {
        return localStorage.getItem('deviceId') || 'tempDeviceId';
    }

    get registrationId(): string {
        return localStorage.getItem('registrationId') || '';
    }

    get returnUrl(): string {
        return localStorage.getItem('returnUrl') || '';
    }

    set returnUrl(url: string) {
        if (url === null) localStorage.removeItem('returnUrl');
        else localStorage.setItem('returnUrl', url);
    }

    clear(): void {
        this.currentLoginUser = null;
        localStorage.removeItem(this.ADMIN_KEY);
    }

    getLoginUser(): LoginApiModel | null {
        if (this.currentLoginUser !== null) {
            return this.currentLoginUser;
        }

        const encryptUser = localStorage.getItem(this.ADMIN_KEY);
        if (!encryptUser) {
            return null;
        }

        try {
            const stringBaseUser = atob(encryptUser);
            this.currentLoginUser = JSON.parse(stringBaseUser);
        } catch (e) {
            console.error('[LoginStorageService]', e);
        }

        return this.currentLoginUser;
    }

    setLoginUser(loginProfile: LoginApiModel): void {
        this.currentLoginUser = null;
        const stringBaseUser = JSON.stringify(loginProfile);
        const encryptUser = btoa(stringBaseUser);
        this.decodeAndSetPermissions(loginProfile.accessToken);
        localStorage.setItem(this.ADMIN_KEY, encryptUser);
        this.loadPermissionsFromStorage();
    }

    isAuth(): boolean {
        if (!this.currentLoginUser?.accessToken) {
            return true;
        }
        return false;
    }

    getAccessToken(): string | null {
        if (this.currentLoginUser === null) {
            return null;
        }

        return this.currentLoginUser.accessToken;
    }

    getUserRefreshToken(): string | null {
        if (this.currentLoginUser === null) {
            return null;
        }

        return this.currentLoginUser.refreshToken;
    }

    updateToken(accessToken: string, refreshToken: string): void {
        const user = this.getLoginUser();
        if (!user) {
            return;
        }

        user.accessToken = accessToken;
        user.refreshToken = refreshToken;

        this.setLoginUser(user);
    }

    getLoginUserName(): string {
        if (this.currentLoginUser === null) {
            return '';
        }

        const encryptAccessToken = this.currentLoginUser.accessToken;

        try {
            const jsonPayload = encryptAccessToken.split('.')[1];
            const decodedPayload = JSON.parse(atob(jsonPayload.replace(/-/g, '+').replace(/_/g, '/')));
            return decodedPayload?.name || '';
        } catch (error) {
            console.error('Failed to decode the JWT:', error);
            return '';
        }
    }

    getLoginUserRole(): string {
        if (this.currentLoginUser === null) {
            return '';
        }

        return this.currentLoginUser.roles[0];
    }

    getLoginUserPermissions(): void {
        if (this.currentLoginUser === null) {
            return;
        }

        const encryptAccessToken = this.currentLoginUser.accessToken;

        try {
            const jsonPayload = encryptAccessToken.split('.')[1];
            const decodedPayload = JSON.parse(atob(jsonPayload.replace(/-/g, '+').replace(/_/g, '/')));
            this.modulePermissions = JSON.parse(decodedPayload?.ModulePermissions);
        } catch (error) {
            console.error('Failed to decode the JWT:', error);
            return;
        }
    }

    hasPermission(moduleId: ModuleEnum, requiredPermission: PermissionEnum): boolean {
        const permissions = this.modulePermissions?.[moduleId] || [];
        return permissions.includes(requiredPermission);
    }

    private loadPermissionsFromStorage(): void {
        const storedPermissions = localStorage.getItem(this.MODULE_PERMISSIONS_KEY);
        this.modulePermissions = storedPermissions ? JSON.parse(storedPermissions) : {};
    }

    private decodeAndSetPermissions(accessToken: string): void {
        try {
            const jsonPayload = accessToken.split('.')[1];
            const decodedPayload = JSON.parse(atob(jsonPayload.replace(/-/g, '+').replace(/_/g, '/')));
            const modulePermissions = JSON.parse(decodedPayload?.ModulePermissions || '{}');
            this.modulePermissions = modulePermissions;
            localStorage.setItem(this.MODULE_PERMISSIONS_KEY, JSON.stringify(modulePermissions));
        } catch (error) {
            console.error('Failed to decode the JWT:', error);
            this.modulePermissions = {};
            localStorage.removeItem(this.MODULE_PERMISSIONS_KEY);
        }
    }
}
